import { createApp as createClientApp, h, Suspense } from "vue";

import { createHead } from "@vueuse/head";
import filters from "addeus-common-library/stores/filters";
import * as Icon from "addeus-common-library/stores/icon";
import "addeus-common-library/utils/enable-input-rules";
import { createPinia } from "pinia";
import AddeusApp from "./components/App.vue";
import "./error-handling";
import { createI18n } from "./i18n";
import { createRouter } from "./router";
import "./scss/index.scss";
import "./styles";

// import { initDarkmode } from "addeus-common-library/stores/darkmode";
export type AddeusAppContext = Awaited<ReturnType<typeof createApp>>;

import { registerGlobalComponents, registerRouterNavigationGuards } from "./app-custom";
import { addGTM } from "./utils/gtm";

Icon.setIconClasses(["material-symbols-rounded"]);

export function createApp() {
    const head = createHead();
    const i18n = createI18n();
    const router = createRouter();
    const pinia = createPinia();

    const app = createClientApp({
        // This is the global app setup function
        setup() {
            /**
             * Initialize the darkmode watcher
             *
addeus-common-library/stores/darkmode
             */
            // initDarkmode();

            /**
             * Here we are creating a render function for our main app with
             * the main AddeusApp component, wrapped in a Suspense component
             * to handle async loading of the app.
             * Template equivalent would be:
             *
             * <template>
             *   <Susupense>
             *     <AddeusApp />
             *   </Susupense>
             * </template>
             */
            return () => {
                /**
                 * The Suspense component is needed to use async in child components setup
                 * @see https://v3.vuejs.org/guide/migration/suspense.html
                 */
                return h(Suspense, null, {
                    default: () => h(AddeusApp),
                });
            };
        },
    });

    const addeus = {
        app,
        router,
        i18n,
        head,
        pinia,
    };

    app.use(addeus.pinia);
    app.use(addeus.head);
    app.use(addeus.i18n);
    registerGlobalComponents(addeus);

    registerRouterNavigationGuards(addeus);
    app.use(addeus.router);

    app.config.globalProperties.$filters = filters;

    if (
        import.meta.env.VITE_FIREBASE_PROJECT_ID.match(/addeus\-(test|staging)/) &&
        import.meta.env.PROD
    ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
            "https://addeus.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/278rlr/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=445251dc";
        document.head.appendChild(script);
    }
    if (import.meta.env.VITE_GTM_ID !== undefined) addGTM(import.meta.env.VITE_GTM_ID);
    return addeus;
}
